import React, { useEffect, useRef } from "react";
import { BotConfig } from "./compoments/initConfig";
import { getRasaResponse, parseResponse } from "./utils/rasaUtil";
import { onToolbarClick } from "./utils/handlers";
import {
  buttonCard,
  signCard,
  markdownCard,
  videoCard,
  paymentCard,
} from "./compoments/card";

const App = () => {
  const wrapper = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const bot = new ChatSDK({
      //query: "hi",
      root: wrapper.current,
      config: BotConfig,
      requests: {
        send: getRasaResponse,
      },
      handlers: {
        parseResponse: parseResponse,
        onToolbarClick: onToolbarClick,
      },
      components: {
        buttonCard: buttonCard,
        signCard: signCard,
        videoCard: videoCard,
        markdownCard: markdownCard,
        paymentCard: paymentCard,
      },
    });
    bot.run();
    //console.log(bot);
  }, []);

  return <div style={{ height: "100%" }} ref={wrapper} />;
};
export default App;
