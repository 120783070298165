import { parseResponse, postToRasa, oid, HotelID } from "./rasaUtil";
import { initBotConfig } from "../compoments/initConfig";
import axios from "axios";
import React from "react";
import payIcon from "../assets/pay_accepted.png";
import "./styles.css";

//var OcrUrl = "http://ai.avatarbot.us:5000/ocr_id";
var OcrUrl = "/ocr_id";
var UploadSignImageUrl = "/f_api/upload_sign_image";
if (process.env.NODE_ENV === "development") {
  OcrUrl = "http://localhost:5000/ocr_id";
  UploadSignImageUrl = "http://localhost:5000/f_api/upload_sign_image";
}

export const onToolbarClick = async (item, ctx) => {
  // 如果点的是“相册”
  if (item.type === "image") {
    ctx.util.chooseImage({
      // multiple: true, // 是否可多选
      success(e) {
        if (e.files) {
          // 如果有 h5 上传的图
          const file = e.files[0];
          // 先展示图片
          ctx.appendMessage({
            type: "image",
            content: {
              picUrl: URL.createObjectURL(file),
            },
            position: "right",
          });
          ctx.appendMessage({
            type: "text",
            content: {
              text: "Recognizing your identification ...",
            },
            position: "left",
          });
          //ctx.appendMessage({
          //  type: "text",
          //  content: { text: "uploaded" },
          //  position: "right",
          //});

          // 临时用的 rasa 消息
          //postToRasa("uploaded").then(function (r) {
          //  let msg = parseResponse(r);
          //  msg.forEach((item) => {
          //    item.user = initBotConfig();
          //    ctx.appendMessage(item);
          //  });
          //});

          // 先禁用掉给 rasa 发送图片
          // 可以先发送一个指定文本作为意图，比如 to upload
          // 然后通过 custom action 读取 slot 内容
          //let fr = new FileReader();
          //fr.onload = async function (e) {
          //  await postToRasa(fr.result).then(function (r) {
          //    let msg = parseResponse(r);
          //    msg.forEach((item) => {
          //      item.user = initBotConfig();
          //      ctx.appendMessage(item);
          //    });
          //  });
          //};
          //fr.readAsDataURL(file);
          const formData = new FormData();
          formData.append("file", file);

          axios
            .post(OcrUrl, formData)
            .then((response) => {
              console.log("File uploaded successfully");
              // 处理成功上传的响应
              console.log(response);
              let firstName = response.data.FirstName;
              let lastName = response.data.LastName;
              let documentNumber = response.data.DocumentNumber;
              let exp_date = response.data.DateOfExpiration;
              let birth_date = response.data.DateOfBirth;

              if (documentNumber !== "") {
                ctx.appendMessage({
                  type: "text",
                  content: {
                    text: `Your name is ${firstName} ${lastName}, ID/Passport number is ${documentNumber}`,
                  },
                  position: "left",
                });
                ctx.appendMessage({
                  type: "text",
                  content: {
                    text: `Finding reservation with your ID info ...`,
                  },
                  position: "left",
                });
                let postData = `/submit_id_passport_ocr_result{"client_name": "${firstName} ${lastName}", "client_id_number": "${documentNumber}", "exp_date": "${exp_date}", "birth_date": "${birth_date}`;
                postToRasa(postData).then(function (r) {
                  let msg = parseResponse(r);
                  msg.forEach((item) => {
                    item.user = initBotConfig();
                    ctx.appendMessage(item);
                  });
                });
              } else {
                // 识别失败
                ctx.appendMessage({
                  type: "text",
                  content: {
                    text: "ID/passport recognition failed. Please upload again.",
                  },
                  position: "left",
                });
              }
            })
            .catch((error) => {
              console.error("Error uploading file:", error);
              // 处理上传错误
            });
        } else if (e.images) {
          // 如果有 app 上传的图
          // ..与上面类似
        }
      },
    });
  }
};

export function CardButtonOnClick(ctx) {
  const innerCardButtonOnClick = async (event) => {
    console.log("event:", event);
    // disable button to prevent duplicate click
    disableBtns(event.target.parentNode.childNodes);

    const userInput = event.target.value;

    let btnMsg = {
      type: "text",
      content: { text: event.target.textContent },
      position: "right",
      //createdAt: Date.now(),
      //hasTime: true,
    };
    ctx.appendMessage(btnMsg);

    if (userInput === "/mobile_save_sign") {
      // 保存签名图片
      let msg = {
        type: "text",
        content: {
          text: "uploading ...",
        },
        position: "left",
      };
      ctx.appendMessage(msg);
      const imgUrl = ctx.signCanvas.toDataURL("image/png");
      await postSignImage(imgUrl).then((r) => {
        console.log(r);
        if (r.error) {
          let msg = {
            type: "text",
            content: {
              text: r.error,
            },
            position: "left",
          };
          ctx.appendMessage(msg);
        } else {
          let msg = {
            type: "text",
            content: {
              text: "OK",
            },
            position: "left",
          };
          ctx.appendMessage(msg);
        }
      });
    }

    await postToRasa(userInput).then((r) => {
      let msg = parseResponse(r);
      msg.forEach((item) => {
        item.user = initBotConfig();
        item.position = "center"; // maybe a bug
        //item.hasTime = true;
        console.log("item:", item);
        ctx.appendMessage(item);
        //disableBtns(event.target.parentNode.childNodes);
        removeBtns(event.target.parentNode);
      });
    });
  };
  return innerCardButtonOnClick;
}

// eslint-disable-next-line no-unused-vars
function disableBtns(btns) {
  for (let btn of btns) {
    btn.disabled = true;
  }
}

function removeBtns(btnsParentNode) {
  let ancestorNode = findAncestorWithClass(btnsParentNode, "Message");
  ancestorNode.remove();
}

// 浏览器中一个节点，向祖先节点中查找一个指定 class 的 node
// 示例用法
//var node = document.getElementById("myNode");
//var ancestorNode = findAncestorWithClass(node, "myClass");
function findAncestorWithClass(element, className) {
  var currentElement = element.parentNode;

  while (currentElement !== null) {
    if (currentElement.classList.contains(className)) {
      return currentElement;
    }

    currentElement = currentElement.parentNode;
  }

  return null; // 如果未找到包含指定 class 的节点，返回 null
}

const postSignImage = async (imgUrl) => {
  const fileObj = dataURLtoBlob(imgUrl);
  const formData = new FormData();
  formData.append("signature_image", fileObj);
  formData.append("hotelID", HotelID);
  formData.append("orderID", oid);

  return await axios
    .post(UploadSignImageUrl, formData)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [{ text: "Network Error" }];
    });
};

// https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export class PayForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvv: "",
      isButtonDisabled: false,
      buttonText: "Pay now",
      errorText: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { ctx, balance } = this.props;

    if (this.state.card_number === "") {
      this.setState({ errorText: "Please input card number" });
      return;
    }
    if (this.state.exp_month === "") {
      this.setState({ errorText: "Please input exp month" });
      return;
    }
    if (this.state.exp_year === "") {
      this.setState({ errorText: "Please input exp year" });
      return;
    }
    if (this.state.cvv === "") {
      this.setState({ errorText: "Please input cvv" });
      return;
    }

    this.setState({ isButtonDisabled: true });
    const formData = {
      cardNumber: this.state.card_number,
      expMonth: this.state.exp_month,
      expYear: this.state.exp_year,
      cvv: this.state.cvv,
      amount: balance,
      //amount: 0.01, // TODO: balance
    };

    this.setState({ buttonText: "Processing" });
    let arg = btoa(JSON.stringify(formData));
    let text = `/post_pay_info{"post_pay_args": "${arg}"}`;
    postToRasa(text).then((r) => {
      this.setState({ buttonText: "Processed" });
      let msg = parseResponse(r);
      msg.forEach((item) => {
        item.user = initBotConfig();
        item.position = "center"; // maybe a bug
        //item.hasTime = true;
        console.log("item:", item);
        ctx.appendMessage(item);
      });
    });
  };

  render() {
    const { isButtonDisabled, buttonText, errorText } = this.state;

    return (
      <div class="panel panel-default credit-card-box">
        <div class="panel-heading display-table">
          <div class="row display-tr">
            <h3 class="panel-title display-td">Payment</h3>
            <div class="display-td">
              <img
                class="img-responsive pull-right"
                alt="accepted cards"
                src={payIcon}
              />
            </div>
          </div>
        </div>
        <div class="panel-body">
          <form id="payment_form">
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <label for="cardNumber">Card Number</label>
                  <div class="input-group full-width">
                    <input
                      type="tel"
                      name="card_number"
                      value={this.state.card_number}
                      onChange={this.handleChange}
                      class="form-control"
                      data-token="card_number"
                      placeholder="Valid Card Number"
                      required=""
                      autofocus=""
                      style={{
                        backgroundImage:
                          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==")',
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "16px 18px",
                        backgroundPosition: "98% 50%",
                        cursor: "pointer",
                      }}
                    />
                    <input type="hidden" id="payment_token" />
                    <span class="input-group-addon">
                      <i class="fa fa-credit-card"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-4 col-md-4" style={{ "padding-right": "0px" }}>
                <div class="form-group">
                  <label>Exp Month</label>
                  <input
                    type="tel"
                    name="exp_month"
                    value={this.state.exp_month}
                    onChange={this.handleChange}
                    class="form-control"
                    data-token="exp_month"
                    placeholder="MM"
                    maxlength="2"
                    required=""
                  />
                </div>
              </div>
              <div class="col-xs-4 col-md-4" style={{ "padding-right": "0px" }}>
                <div class="form-group">
                  <label>Exp Year</label>
                  <input
                    type="tel"
                    name="exp_year"
                    value={this.state.exp_year}
                    onChange={this.handleChange}
                    class="form-control"
                    data-token="exp_year"
                    placeholder="YYYY"
                    maxlength="4"
                    required=""
                  />
                </div>
              </div>
              <div class="col-xs-4 col-md-4 pull-right">
                <div class="form-group">
                  <label>CVV Code</label>
                  <input
                    type="tel"
                    name="cvv"
                    value={this.state.cvv}
                    onChange={this.handleChange}
                    class="form-control"
                    data-token="cvv"
                    placeholder="CVV"
                    maxlength="4"
                    required=""
                  />
                </div>
              </div>
            </div>
            <div class="row" style={{ "margin-top": "20px" }}>
              <div class="col-xs-12">
                <button
                  class="btn btn-success btn-lg btn-block"
                  onClick={this.handleSubmit}
                  disabled={isButtonDisabled}
                >
                  {buttonText}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <p class="payment-errors text-center" id="payment_errors">
                  {errorText}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
