import botAvatar from "../assets/sara_avatar.png";

export function createTextBotMsg(botText, msgType) {
  switch (msgType) {
    case "system": {
      return {
        type: "system",
        content: { text: botText },
      };
    }
    case "user": {
      return {
        type: "text",
        content: { text: botText },
        position: "right",
      };
    }
    default: {
      return {
        type: "text",
        content: { text: botText },
      };
    }
  }
}

export function createImgBotMsg(src) {
  return {
    type: "image",
    content: { picUrl: src },
    user: { avatar: botAvatar },
  };
}

export function createCardMsg(data, cardType) {
  switch (cardType) {
    default: {
      return {
        type: "card",
        content: {
          code: "buttonCard",
          data: data,
        },
        user: { avatar: botAvatar },
      };
    }
  }
}

export function createVideoMsg(data, cardType) {
  switch (cardType) {
    default: {
      return {
        type: "card",
        content: {
          code: "videoCard",
          data: data,
        },
        user: { avatar: botAvatar },
      };
    }
  }
}

export function createCardSign(data, cardType) {
  switch (cardType) {
    default: {
      return {
        type: "card",
        content: {
          code: "signCard",
          data: data,
        },
        user: { avatar: botAvatar },
      };
    }
  }
}

export function createCardMarkdown(data, cardType) {
  switch (cardType) {
    default: {
      return {
        type: "card",
        content: {
          code: "markdownCard",
          data: data,
        },
        user: { avatar: botAvatar },
      };
    }
  }
}

export function createCardPayment(data, cardType) {
  switch (cardType) {
    default: {
      return {
        type: "card",
        content: {
          code: "paymentCard",
          data: data,
        },
        user: { avatar: botAvatar },
      };
    }
  }
}
