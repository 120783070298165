import axios from "axios";
import {
  createCardMsg,
  createCardSign,
  createCardPayment,
  createImgBotMsg,
  createTextBotMsg,
  createCardMarkdown,
  createVideoMsg,
} from "./msgManager";

//var RasaRestUrl = "http://ai.avatarbot.us/webhooks/rest/webhook";
var RasaRestUrl = "/webhooks/rest/webhook";
if (process.env.NODE_ENV === "development") {
  RasaRestUrl = "http://localhost:5005/webhooks/rest/webhook";
}

// 初始化时，随机一个 sender_id
const SenderId = generateSenderId();

// URL 示例:
// http://localhost:3000/?oid=123&query=hi
// 正式 hotel id 对应的链接。不需要 hid 参数，默认即为正式 hotel id
// https://ai.avatarbot.us/chat/?query=hi&oid=100005630064
// 正式 hotel id 对应的链接，增加了 hid 参数，这里 hid=2 即代表测试 hotel
// https://ai.avatarbot.us/chat/?query=hi&oid=100005630064&hid=2
//
// order_id 的处理方式：
// url 中提供 order_id 的目的是，方便定位当前的订单是哪个。
// 但是，第一次打开时，无法判定订单是否是当前用户的，所以需要身份认证一下。
//
// 认证的时机:
// 因为链接里自带 query hi，所以当收到 rasa 返回的菜单时，判断本地是否有缓存的
// 用户的姓名。如果有说明是通过身份证上传，OCR 识别验证过的姓名。
// 没有这个缓存的姓名，说明没有认证过。就需要提示上传身份证。
//
// 认证方式:
// 上传身份证，返回姓名，同时利用 URL 中获取的 order_id 请求 PMS 的
// 订单详情接口。比对身份证中的姓名和订单中姓名是否一致。
// 如果一致，就将 first / last name 缓存到本地。
// 可以先用 base64 编码后的值，做存储，避免泄露。
// 后续可以考虑使用 jwt token 来实现。有了 jwt token 之后，再清掉 base64 的方案。
//
// 实现方式：
// 比对过程应该在服务端实现比较合理。即上传接口中，带上 order_id。
// ocr 完成后，再调用 PMS 订单接口，比对 name。
// 认证成功再返回结果，及 first / last name 等。
// 唯一的问题就是需要等待的时间太长，是否需要分割为两个接口。
const urlParams = new URLSearchParams(window.location.search);
export const oid = urlParams.get("oid"); // 订单 id, 即 order_id, oid
console.log("oid: " + oid);
const hid = urlParams.get("hid"); // hotel id, 默认没有 hid 参数时是正式环境，hid=2 是测试环境
console.log("hid: " + hid);
var hotelID = "FIEWQUX4NZRKSP1OB8TAG52DJ7VYC6MH"; // 正式
if (hid === "2") {
  console.log("debug hotel id");
  hotelID = "SEH5vJvJbtvadTJZpHZ4KRDwwhUhynJ7"; // 测试
}
export const HotelID = hotelID;
// local storage 用于存储是否验证通过的标识
// 例如，在用户打开链接时，先验证房间号是否匹配，如果匹配，这个值就是 "yes"
const validateStatusKey = `${oid}_status`;

export const postToRasa = async (userInput) => {
  return await axios
    .post(RasaRestUrl, {
      sender: SenderId,
      message: userInput,
      metadata: {
        hotelID: hotelID,
        order_id: oid,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [{ text: "Network Error", recipient_id: SenderId }];
    });
};

export const getRasaResponse = async (msg) => {
  const data = msg.content;
  // 发送文本消息时
  if (msg.type === "text") {
    if (data.text === "hi") {
      if (!isUserValidated()) {
        // 若未验证（房间号匹配），则不发送 hi，改发送 intent "请输入房间号"
        // 因为 metadata 中包含了 order id，所以不需要再附加 order id
        return await postToRasa("/mobile_enter_room_number");
      } else {
        return await postToRasa("/mobile_order_detail_and_menu");
      }
    } else {
      // 发送用户输入的文本
      return await postToRasa(data.text);
    }
  }
};

export function parseResponse(res, reqType) {
  let botResponse = [];

  if (res === undefined) {
    return botResponse;
  }
  console.log("Res:", res);
  res.forEach((item) => {
    let msg;
    if (item.text !== undefined && item.text !== "") {
      msg = createTextBotMsg(item.text);
      botResponse.push(msg);
    }
    if (item.image !== undefined) {
      let src = decodeURI(item.image);
      msg = createImgBotMsg(src);
      botResponse.push(msg);
    }
    if (item.custom !== undefined && item.custom.sign !== undefined) {
      msg = createCardSign(item.text);
      botResponse.push(msg);
    }
    if (item.custom !== undefined && item.custom.video !== undefined) {
      msg = createVideoMsg(item.custom.video);
      botResponse.push(msg);
    }
    if (item.buttons !== undefined) {
      msg = createCardMsg(item.buttons);
      botResponse.push(msg);
    }
    // markdown
    if (item.custom !== undefined && item.custom.markdown !== undefined) {
      msg = createCardMarkdown(item.custom.markdown);
      botResponse.push(msg);
    }
    // payment card
    if (item.custom !== undefined && item.custom.payment !== undefined) {
      if (item.custom.payment.type === "mobile_pay") {
        msg = createCardMarkdown(getPaymentMarkdown(item.custom.payment));
        botResponse.push(msg);
        msg = createCardPayment(item.custom.payment);
        botResponse.push(msg);
      }
    }
    // exit
    if (item.custom !== undefined && item.custom.exit !== undefined) {
      setTimeout(() => {
        window.location.reload();
      }, item.custom.exit * 1000);
    }
    // mobile 房间号认证
    if (item.custom !== undefined && item.custom.mobile_validate === "yes") {
      setUserValidated();
    }
  });
  return botResponse;
}

function generateSenderId() {
  let timestamp = Date.now();
  let randomSuffix = Math.floor(Math.random() * 10000); // 生成 0-9999 之间的随机数
  return timestamp + "_" + randomSuffix.toString().padStart(4, "0") + "_web";
}

function isUserValidated() {
  let value = localStorage.getItem(validateStatusKey);
  if (value === "yes") {
    return true;
  } else {
    return false;
  }
}

function setUserValidated() {
  localStorage.setItem(validateStatusKey, "yes");
}

function getPaymentMarkdown(data) {
  return `| Total | Paid | Balance |
| :-: | :-: | :-: |
| $${data.totalCharge} | $${data.paid} | $${data.balance} |
`;
}
