import botAvatar from "../assets/sara_avatar.png";
import userAvatar from "../assets/userAvatar.jpg";
import { defaultQuickReplies } from "./quickReplys";
//import { createTextBotMsg } from "../utils/msgManager";

const initialMessages = [
  //createTextBotMsg(
  //  "AvatarBot Receptionist enters the conversation, and serves you",
  //  "system"
  //),
  //createTextBotMsg('久等了，你可以随便问问问哦！'),
  // 禁用
  //createTextBotMsg("I am AvatarBot Receptionist, you can ask anything!"),
  //createTextBotMsg("hi", "user"),
];

function initNavBar() {
  return {
    //title: "AvatarBot Receptionist",
    title: "AvatarBot",
  };
}

export function initBotConfig() {
  return {
    avatar: botAvatar,
  };
}

function initUserConfig() {
  return {
    avatar: userAvatar,
  };
}

export const BotConfig = {
  navbar: initNavBar(),
  robot: initBotConfig(),
  user: initUserConfig(), // 用户头像
  messages: initialMessages,
  //quickReplies: defaultQuickReplies, //快捷短语
  placeholder: "Start typing a message ...", // 输入框占位符
  toolbar: [
    {
      type: "image",
      icon: "image",
      title: "Image",
    },
  ],
};
