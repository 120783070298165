import { Button, Card, CardActions } from "@chatui/core";
//import React from "react";
import { CardButtonOnClick, PayForm } from "../utils/handlers";
import SignatureCanvas from "react-signature-canvas";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./styles.css";

//<CardActions style={{ "flex-wrap": "wrap" }}>
export function buttonCard({ data, ctx, meta }) {
  return (
    <Card
      size="xl"
      style={{ "max-width": "90%", width: "auto", "min-width": "300px" }}
    >
      <CardActions style={{ "flex-wrap": "wrap" }}>
        {data.map((item, index) => {
          return (
            <Button
              key={index}
              onClick={CardButtonOnClick(ctx)}
              value={item.payload}
              style={{
                margin: "5px",
                "white-space": "pre-line",
                color: "rgb(255, 255, 255)",
                "background-color": "rgb(224, 38, 120)",
                "border-color": "rgb(224, 38, 120)",
                padding: "5px 8px",
                "min-height": "52px",
                "border-radius": "2px",
                "max-width": "260px",
              }}
            >
              {item.title}
            </Button>
          );
        })}
      </CardActions>
    </Card>
  );
}

export function signCard({ data, ctx, meta }) {
  return (
    <Card size="xl">
      <CardActions style={{ "flex-wrap": "wrap" }}>
        <SignatureCanvas
          penColor="black"
          ref={(ref) => {
            //this.sigCanvas = ref;
            ctx.signCanvas = ref;
          }}
          canvasProps={{ width: 300, height: 150 }}
        />
      </CardActions>
    </Card>
  );
}

export function videoCard({ data, ctx, meta }) {
  return (
    <div>
      <div>
        <iframe
          title="Door Opening Video"
          src="https://player.vimeo.com/video/885100735?h=536b556715&autoplay=0&loop=1&title=0&byline=0&portrait=0"
          frameborder="0"
          width="300"
          height="361"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}

export function videoCardYoutube({ data, ctx, meta }) {
  return (
    <iframe
      width="300"
      height="169"
      src={data}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
}

export function markdownCard({ data, ctx, meta }) {
  return (
    <Card
      size="xl"
      style={{ "max-width": "90%", width: "auto", "min-width": "300px" }}
    >
      <CardActions
        className="markdown_outer_card"
        style={{
          "flex-wrap": "wrap",
        }}
      >
        <div class="markdown_container">
          <Markdown remarkPlugins={[remarkGfm]} children={data} />
        </div>
      </CardActions>
    </Card>
  );
}

export function paymentCard({ data, ctx, meta }) {
  return (
    <Card
      size="xl"
      style={{
        "max-width": "95%",
        width: "auto",
        "min-width": "300px",
        "padding-bottom": "20px",
      }}
    >
      <PayForm ctx={ctx} balance={data.balance}></PayForm>
    </Card>
  );
}

/*
              {item.title.replace(/\n/g, "<br>")}
export function muliLineButtonCard({ data, ctx, meta }) {
  return (
    <Card size="xl">
      <CardActions style={{ "flex-wrap": "wrap" }}>
        {data.map((item, index) => {
          return (
            <Button
              key={index}
              onClick={CardButtonOnClick(ctx)}
              value={item.payload}
              style={{ margin: "5px" }}
            >
              {item.title}
            </Button>
          );
        })}
      </CardActions>
    </Card>
  );
}
*/
